/* 
 * @Author: 曹俊杰 
 * @Date: 2022-06-08 10:57:28
 * @Module: 违约列表组件
 */
 <template>
  <div class="exposureList">
    <div class="search">
      <el-input clearable size="small" @keyup.enter.native="()=>$emit('onSearch')" :value="search" @input="value=>$emit('update:search', value)" style="width:220px;" placeholder="请输入姓名或身份证号" prefix-icon="el-icon-search">
      </el-input>
      <el-button @click="()=>$emit('onSearch')" type="primary" size="small" style="margin-left:20px;">搜索</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;">
      <el-table-column type="expand" width="54px">
        <template slot-scope="props">
          <div class="content">
            <h1>违约情况：</h1>
            <p>{{ props.row.content }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="曝光日期">
        <template slot-scope="scope">
          <span v-if="scope.row.createdAt">{{ scope.row.createdAt|timeFormat }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="违约主播">
        <template slot-scope="scope">
          <span style="color:#FF8412;">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="违约主播身份证号码">
        <template slot-scope="scope">
          <span style="color:#FF8412;">{{ scope.row.idNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="曝光方">
        <template slot-scope="scope">
          <span style="color:#666;">{{ scope.row.companyName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="曝光账号" width="130">
        <template slot-scope="scope">
          <div class="flex">
            <span style="color:#666;">{{ scope.row.empName }}</span>
            <div class="icon success"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="删除" v-if="showDelete" width="50" align="center">
        <template slot-scope="scope">
          <span>
            <el-link type="danger" icon="el-icon-delete" :underline="false" @click="()=>$emit('onDelete',scope)"></el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    search: {
      type: String
    },
    tableData: {
      type: Array,
      default: () => ([])
    },
    showDelete: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    };
  },
  mounted () { },
  methods: {

  },
};
 </script>
 <style lang='scss' scoped>
.exposureList {
  & /deep/ .el-table__expanded-cell {
    padding: 0;
  }
  .search {
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .icon {
    width: 60px;
    height: 20px;
    margin-left: 6px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .success {
    background-image: url(~@/static/image/yirenzheng.png);
    background-size: 100%;
  }
  .content {
    background: #f7f8f9;
    padding: 14px 24px 14px 64px;
    h1 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 28px;
    }
  }
}
</style>